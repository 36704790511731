<script setup lang="ts">
import { Analytics } from '~/js/interfaces/analytics'
import { Blog } from '~/js/interface/blog'

import { getGlobalTranslation } from '~/js/composables/translations'

import TextH2 from '~/vue/_atoms/texts/TextH2.vue'
import TextBody from '~/vue/_atoms/texts/TextBody.vue'
import LinkOverlay from '~/vue/_atoms/links/LinkOverlay.vue'
import ImageTeaser from '~/vue/_atoms/images/ImageTeaser.vue'

interface Props {
    card: Blog,
    utilities?: string,
    ga?: Analytics | null
}

withDefaults(defineProps<Props>(), {
    utilities: ''
})

const types = (type) => {
    const lut = {
        'articlePage': getGlobalTranslation('Artikel'),
        'podcastPage': getGlobalTranslation('Podcast'),
        'videoPage': getGlobalTranslation('Video'),
    }

    return lut[type] ?? lut['articlePage']
}
</script>

<template>
    <article 
        :class="[
            'm-auto shadow-md relative',
            'rounded-md rounded-br-3xl',
            'group',
            utilities
        ]"
    >
        <div 
            :class="[
                'flex flex-col sm:block justify-between',
                'bg-beige-600',
                'rounded-br-3xl',
                'relative overflow-hidden',
            ]"
        >
            <ImageTeaser
                v-if="card.teaser"
                :image="card.teaser"
                utilities="sm:absolute sm:right-0 sm:top-0 group-hover:scale-[150%] transition-all duration-500 w-full sm:w-[450px]"
                ratio="aspect-auto"
                :sizes="['25vw']"
            />

            <div class="w-full sm:w-[calc(100%-280px)] -mt-12 sm:mt-0 space-y-4 px-4 py-8 bg-beige-600 z-10 rounded-tr-[50px] sm:rounded-tr-[100px] relative">
                <h2 class="block pr-8 w-full text-ellipsis overflow-hidden">
                    <TextH2>
                        {{ card.title }}
                    </TextH2>
                </h2>

                <div>
                    <TextBody utilities="line-clamp-3">
                        {{ card.description }}
                    </TextBody>
                </div>

                <div class="flex space-x-2 text-xs font-primary">
                    <span class="text-purple-600 text-xs font-bold">{{ card.post_date }}</span>
                    <span class="uppercase text-purple-500 font-bold">{{ types(card.type) }}</span>
                </div>
            </div>

                <span class="hidden md:block absolute right-4 bottom-4 bg-yellow-600 text-purple-600 font-primary font-bold rounded-sm rounded-br-2xl py-2 px-4 gap-2  w-fit"> 
                    {{getGlobalTranslation('Bekijk details')}}
                </span>
        </div>

        <LinkOverlay
            :title="card.title"
            :url="card.url"
            :ga="ga ?? {
                category: 'card-blog',
                action: 'click',
                label: card.title
            }"
        />
    </article>
</template>