<script setup lang="ts">
import { computed, ref } from 'vue'
import { getClasses } from '~/js/composables/classes'
import { Image } from '~/js/interfaces/media'

interface Props {
    image: Image,
    ratio?: string,
    sizes?: Array<string>,
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), {
    ratio: 'aspect-4/3',
    utilities: ''
})

const classes = ref({
    image: {
        object: 'object-cover object-center',
        size: 'w-full h-full'
    }
})

const sizes = computed(() => {
    if (props.sizes) {
        return props.sizes.join(',')
    }

    return '100vw'
})

</script>
<template>
    <figure 
        :class="[utilities, ratio]"
    >
        <picture>
            <source
                :srcset="image.srcsetWepP"
                :sizes="sizes"
                type="image/webp"
            >

            <source
                :srcset="image.srcset"
                :sizes="sizes"
            >

            <img 
                :src="image.placeholder"
                :srcset="image.srcset"
                :sizes="sizes"
                :class="getClasses(classes, 'image')"
                :alt="image.alt"
                loading="lazy"
            >
        </picture>
    </figure>
</template>